export default {
  blogerName: 'JR50G',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@eusoujoaofranca',
    },
    {
      name: 'telegram',
      url: 'https://t.me/vidadejogos',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/eusoujoaofranca'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/cd5214878',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c31f2f552',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cc4437c81',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c299d834f',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/ce668c2cd',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c431bf7f3',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c0f31695e',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c9e8c3fc7',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
  ],
  headerText: 'Cadastre-se com um código promocional <strong>JR50G</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'JR50G',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
